import Isotope from 'isotope-layout';
import jQueryBridget from 'jquery-bridget';

var imagesLoaded = require('imagesloaded');

jQueryBridget('isotope', Isotope, $);

$(function () {
    // now doc is ready, make selection
    // use another selector, not .isotope,
    // since that is dynamically added in Isotope v1
    var $container = $('.grid');

    imagesLoaded.makeJQueryPlugin($);


    var $grid = $container.imagesLoaded(function () {
        // images have loaded
        $container.isotope({
            itemSelector: '.local-people__teaser',
            masonry: {
                columnWidth: 50,
                gutter: 20
            },
            filter: '*'
        }).imagesLoaded( function() {
            $container.isotope('layout');
        });
    });


    $('.filter-button-group').on('click', 'button', function () {
        var filterValue = $(this).attr('data-filter');
        $('.button-group .lp-filter').removeClass('active');
        $(this).addClass('active');
        $grid.isotope({filter: filterValue});
    });

});


function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}