import './orig/core';
import 'bootstrap';
import 'local-people';
// import '../../node_modules/@fortawesome/fontawesome-free/js/all_used';
// import './klaro/klaroConfig';
// import './slider';
// import './menu';
// import './mapbox';
// import './button';
// import './animations';
